import React from "react"
import { FormContentful } from "../FormContentful/FormContentful"

export const FormOperator = ({ title, handlerThankYou, serviceInterest }) => {

  return (
    <section className="form-operator">
      <FormContentful
        title={title}
        handlerThankYou={handlerThankYou} 
        storageGoogleSheets 
        formKey="form_operator" 
        fieldsAdditional={{ ServicioInteres: serviceInterest }}
      />
    </section>
  )
}
