import React, { useEffect, useState } from "react"
import { Form, Input, Button, Radio, Select, Row, Col, InputNumber } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { removeNewLead, updateNewLead } from "../../../../../../@common/storage/newLead"
import ArrowBack from "../../../../../../assets/images/arrow-back.svg"
import { liftersView as LiftersViewActions } from "../../../../../../services/LiftersView/LiftersViewActions"
import InputPhone from "../../../../../InputPhone/InputPhone"
import { useContentfulGeneral } from "../../../../../../contenful/querys/General"
import { navigatePage } from "../../../../../../@common/utils/navigate"
import { addSpreedSheet } from "../../../../../../@common/services/googleSheets"
import { FORMS_LIFTERS } from "../../../../../../@common/constansts/googleSheets"

const { Option } = Select
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

// label is key of i18n
const availables = [
  { label: "monday-friday", key: "monday-friday" },
  { label: "saturday-sunday", key: "saturday-sunday" },
  { label: "fulltime", key: "fulltime" },
]

export const formKeysGoogleSheets = {
  type_person: 'TipoPersona',
  name: 'Nombre',
  prefix: 'Prefijo',
  phone: 'Telefono',
  prefix_country: 'PrefijoPais',
  rol: 'Rol',
  type_document: 'TipoDocumento',
  email: 'Correo',
  city: 'Ciudad',
  available: 'Disponibilidad',
  type_license: 'TipoLicencia',
  certification: 'CertificacionCurso',
  certification_other: 'OtraCertificacion',
  how_did_find_out: 'ComoSeEntero',
  plate: 'Placa',
}

const FormPersonNatural = ({ contenfulNewLead, handlerStep, handlerCloseModal, buttonText }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formStep1] = Form.useForm()
  const [formStep2] = Form.useForm()

  const {
    error: { sendForm: hubspotSendFormError },
    success: { sendForm: hubspotSendFormSuccess },
  } = useSelector(state => state.hubspot)
  const { languageISO639 } = useSelector(state => state.language)
  const { newLead } = useSelector(state => state.liftersView)
  const { countries } = useSelector(state => state.poetri)

  const [courseOrCertification, setCourseOrCertification] = useState()
  const [step, setStep] = useState(1)
  const contenfulGeneral = useContentfulGeneral(languageISO639)

  useEffect(() => {
    if (hubspotSendFormSuccess) {
      removeNewLead()
      navigatePage("/lifters-thank-you")
    }
  }, [hubspotSendFormSuccess])

  const submitStep1 = values => {
    dispatch(LiftersViewActions.setNewLead({ ...newLead, ...values }))
    if (handlerStep)
      handlerStep(2)
    setStep(2)
  }

  const submitStep2 = async values => {
    dispatch(LiftersViewActions.setNewLead({ ...newLead, ...values }))

    /*Save in google sheets*/
    const params = { ...newLead, ...values, prefix_country: newLead.prefix }
    const paramsGoogleSheets = {}
    Object.keys(params).map(key => {
      if (formKeysGoogleSheets[key])
        paramsGoogleSheets[formKeysGoogleSheets[key]] = params[key]
    })

    let indexCountry

    countries.find((country, index) => {
      if (country.alpha2code === newLead.prefix) {
        indexCountry = index
        return country
      }
    })

    const responseSheet = await addSpreedSheet(
      FORMS_LIFTERS.spreadsheetId,
      paramsGoogleSheets,
      indexCountry
    )

    sessionStorage.setItem('lifters_row', responseSheet._rowNumber)
    navigatePage("/lifters-thank-you")
    /*Save in google sheets*/

    /*dispatch(HubspotActions.sendForm(
      HUBSPOT.FORMS_NEW_LEAD_NATURAL.id,
      HUBSPOT.FORMS_NEW_LEAD_NATURAL.keys,
      { ...newLead, ...values, prefix_country: newLead.prefix }
    ))*/
  }

  const updateStorage = value => updateNewLead(value)

  if (!contenfulNewLead) return null

  return (
    <>
      {newLead && step === 1 && (
        <Form
          form={formStep1}
          {...layout}
          name="formStep1"
          onFinish={submitStep1}
          onValuesChange={updateStorage}
          initialValues={newLead || { prefix: "CO" }}
        >
          <div className="form-steps__first-step">
            <p className="form-lifters__label" />

            <div className="form-steps__contentFlexbox">
              <Form.Item
                label={t("general.name")}
                name="name"
                className="label-custom"
                rules={[{ required: true, message: t("form.error.required") }]}
              >
                <Input />
              </Form.Item>
              <div className="form-lifters__label__input-phone">
                <p className="form-lifters__label">
                  {t("general.label_phone")}
                </p>

                <InputPhone
                  FormParent={Form}
                  formRef={formStep1}
                  prefixDetected
                  classInputGroup="form-lifters__group-phone"
                />
              </div>
            </div>

            <Form.Item
              name="rol"
              className="items-rol"
              label={contenfulNewLead.rol.label}
              className="label-custom"
              rules={[{ required: true, message: t("form.error.required") }]}
            >
              <Radio.Group className="input-radio-list">
                {contenfulNewLead.rol.links.map(option => (
                  <Radio key={option.key} value={option.label}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              className="form-lifters__submit button-submit-form"
              name="buttons"
            ></Form.Item>
            <Button
              htmlType="submit"
              className="btn-custom btn-custom--primary"
              size="large"
            >
              {buttonText? buttonText: t("button.save_and_continue")}
            </Button>
            {handlerCloseModal &&
              <button
                className="ravert"
                type="button"
                onClick={handlerCloseModal}
              >
                <img src={ArrowBack} alt="arrowBack" />
                <span>{t("button.return")}</span>
              </button>
            }
          </div>
        </Form>
      )}

      {/* // STEP 2 */}
      {step === 2 && (
        <Form {...layout} form={formStep2} name="form2" onFinish={submitStep2}>
          <div className="form-steps__second-step">
            <Row>
              <Col span={10}>
                <div className="form-steps__second-step__cedula">
                  <p className="form-lifters__label">
                    {t('general.document')}
                  </p>
                  <Input.Group className="form-lifters__group-phone">
                    <Form.Item
                      name="type_document"
                      noStyle
                      rules={[
                        { required: true, message: t("form.error.required") },
                      ]}
                    >
                      <Select>
                        {contenfulGeneral.type_documents?.links.map(document => (
                          <Option key={document.key} value={document.key}>{document.key}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="document"
                      rules={[
                        { required: true, message: t("form.error.required") },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="0000 0000"
                      />
                    </Form.Item>
                  </Input.Group>
                </div>

                <Form.Item
                  label={t("general.email")}
                  name="email"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                    { type: "email", message: t("form.error.email") },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("general.city")}
                  name="city"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="available"
                  label={t("general.available")}
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <Radio.Group className="input-radio-list">
                    {availables.map(item => (
                      <Radio key={item.key} value={t(`general.${item.label}`)}>
                        {t(`general.${item.label}`)}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>

                <p className="form-lifters__label">
                  {t("general.type_license")}
                </p>
                {formStep1.getFieldValue("rol") !== "assistant" && (
                  <Form.Item
                    name="type_license"
                    rules={[
                      { required: true, message: t("form.error.required") },
                    ]}
                  >
                    <Radio.Group className="form-lifters__radio-group">
                      {contenfulGeneral.types_licenses?.links.map(item => (
                        <Radio.Button
                          key={item.key}
                          value={item.label}
                          className="form-lifters__radio-groupInput"
                        >
                          {item.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
              </Col>
              <Col span={10} offset={2}>
                <Form.Item
                  className="label-custom select-how-did"
                  name="how_did_find_out"
                  label={contenfulNewLead.how_did_you_find_out.label}
                  hasFeedback
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <Select placeholder={t("general.select_options")}>
                    {contenfulNewLead.how_did_you_find_out.links.map(
                      option => (
                        <Option key={option.key} value={option.label}>
                          {option.label}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                {formStep1.getFieldValue("rol") !== "owner" && (
                  <Form.Item
                    name="certification"
                    label={contenfulNewLead.course_cetification.label}
                    className="label-custom"
                    onChange={e => setCourseOrCertification(e.target.value)}
                    rules={[
                      { required: true, message: t("form.error.required") },
                    ]}
                  >
                    <Radio.Group className="input-radio-list">
                      {contenfulNewLead.course_cetification.links.map(
                        option => (
                          <Radio key={option.key} value={option.label}>
                            {option.label}
                          </Radio>
                        )
                      )}
                    </Radio.Group>
                  </Form.Item>
                )}

                {courseOrCertification === "other" && (
                  <Form.Item
                    label={t("form.form_new_lead.certification_other")}
                    name="certification_other"
                    className="label-custom"
                  >
                    <Input />
                  </Form.Item>
                )}

                {formStep1.getFieldValue("rol") !== "assistant" && (
                  <Form.Item
                    label={t("general.plate")}
                    name="plate"
                    className="label-custom"
                    rules={[
                      { required: true, message: t("form.error.required") },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </Col>

              {hubspotSendFormError && <span>{t('form.error.' + hubspotSendFormError)}</span>}

              <Form.Item
                className="form-lifters__submit button-submit-form"
                name="buttons"
              >
                <>
                  <Button
                    htmlType="submit"
                    className="btn-custom btn-custom--primary"
                  >
                    {t("button.send")}
                  </Button>
                  <button
                    className="ravert"
                    type="button"
                    onClick={() => setStep(1)}
                  >
                    <img src={ArrowBack} alt="arrowBack" />
                    <span>{t("button.back_step_1")}</span>
                  </button>
                </>
              </Form.Item>
            </Row>
          </div>
        </Form>
      )}
    </>
  )
}

export default FormPersonNatural
