import React, { useState, useEffect } from "react"
import { Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useContentfulForm } from "../../../contenful/querys/Forms"
import FormPersonNatural from "../../PageLifters/FormNewLead/Steps/components/FormPersonNatural/FormPersonNatural"
import { useContenfulNewLead } from "../../../contenful/querys/Forms/FormNewLead"
import { liftersView as LiftersViewActions } from "../../../services/LiftersView/LiftersViewActions"
import { FormGenerator } from "../../Forms/FormGenerator/FormGenerator"
import { FormOperator } from "../../Forms/FormOperator/FormOperator"
import { ThankYou } from "../../Forms/ThankYou/ThankYou"

export const ModalNewLead = ({ keyForm, visible, handlerClose, params }) => {

  const dispatch = useDispatch()

  const { newLead } = useSelector(state => state.liftersView)
  const { languageISO639 } = useSelector(state => state.language)
  const [thankYou, setThankYou] = useState(false)

  const contentfulModalNewLead = useContentfulForm(languageISO639, keyForm)
  const contenfulNewLead = useContenfulNewLead(languageISO639)

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        node.content[0].value === "" ?
          <br /> : <p>{children}</p>
    }
  }

  useEffect(() => {
    setThankYou(false)
  }, [])

  useEffect(() => {
    if (!newLead && keyForm === 'form_lifter')
      dispatch(LiftersViewActions.setNewLead({ type_person: 'natural' }))
  }, [keyForm])

  return (
    <div className="modal-new-lead">
      <Modal
        className="modal-new-lead--modal"
        title="Basic Modal"
        visible={visible}
        onOk={() => handlerClose(false)}
        onCancel={() => handlerClose(false)}
      >
        <div className="SectionFormIam">
          <div className="SectionFormIam__video">
            <h1 className="title">
              {contentfulModalNewLead?.title}
            </h1>
            <div className="text">
              {documentToReactComponents(contentfulModalNewLead?.description?.json, options)}
            </div>
            {/*<ReactPlayer
              url="https://www.youtube.com/watch?v=0FHSSnm9MRg&feature=emb_title"
              className="video"
              playing={false}
              config={{
                youtube: {
                  playerVars: {
                    controls: 0,
                    fs: 0,
                    modestbranding: 0,
                  },
                },
              }}
            />*/}
          </div>
          <div className="SectionFormIam__form">
            <div className="form-contact">
              {thankYou &&
                <ThankYou handlerClose={() => handlerClose(false)} handlerThankYou={setThankYou} />
              }
              {!thankYou && keyForm === 'form_generator' &&
                <FormGenerator
                  handlerThankYou={setThankYou}
                  dropdowns={contentfulModalNewLead.dropdown}
                  initialValues={params ? { servicio_de_tu_interes_: params.serviceInterest.key } : {}}
                />
              }
              {!thankYou && keyForm === 'form_lifter' &&
                <FormPersonNatural buttonText={contentfulModalNewLead?.button} contenfulNewLead={contenfulNewLead} />
              }
              {!thankYou && keyForm === 'form_operator' &&
                <FormOperator
                  title={contentfulModalNewLead?.title_form}
                  handlerThankYou={setThankYou}
                  serviceInterest={params?.serviceInterest?.name}
                />
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
